<template>
  <div class="page-content detailed">

    <detail-banner :title="title" :content="content" :categories="readAbleCategories" :slides="slides" :institutionName="institutionName" :institutionId="institutionContentId"/>     

    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12 pr-lg-3">

          <div class="row mb-3">
            <div class="col-12">
              <ul class="info--list" id="horizontal-style">
                <li class="info-list" v-if="street && city">
                  <div class="info-list__icon">
                    <span class="material-icons">room</span>
                  </div>
                  <div class="info-list__content">
                    <div>{{street}}</div>
                    <div>{{city}}</div>
                  </div>
                </li>
                <li class="info-list" v-if="appointments">
                  <div class="info-list__icon">
                    <span class="material-icons">schedule</span>
                  </div>
                  <div class="info-list__content">
                    <appointment :appointments="appointments"/>
                    <div v-if="appointments.days.length>1">
                      <button v-show="appointments!=null" v-if="!showAppointments" @click="toggleAppointments" class="btn btn-link">Zeige weitere Termine</button>
                      <button v-show="appointments!=null" v-else @click="toggleAppointments" class="btn btn-link">Termine verbergen</button>
                    </div>
                  </div>
                </li>
                <li class="info-list" v-if="url">
                  <div class="info-list__icon">
                    <span class="material-icons">home</span>
                  </div>
                  <div class="info-list__content" >
                    <a :href="url" target="_blank">{{shortenedUrl}}</a>
                  </div>
                </li>
              </ul>
            </div>

          </div>

          <div class="koultour-text mt20 mb20" v-if="audioWalk">
            <AudioCard :audioWalk="audioWalk" contentType="dieses Event"/>
          </div>

          <div class="row content-wrapper">

            <div v-show="showMap" :class="contentClass">
              <div class="wrapper map-wrapper">
                <h1 class="section-title">Karte</h1>
                <the-map class="map" :address-obj="addressObj"></the-map>
              </div>
            </div>

            <div v-show="showAppointments" :class="contentClass">
              <div class="wrapper appointment-wrapper">
                <h1 class="section-title">Termine</h1>
                <div class="info-list__content--dropdown">
                  <appointments-list :appointments="appointments" :active="true"/>
                </div>
              </div>
            </div>

            <div :class="contentClass">
              <div class="wrapper text-wrapper">
                <h1 class="section-title" v-html="title"></h1>
                <div v-html="description"></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" v-if="false">
              <card-slider :data="highlights" title="Highlights in der Nähe" card="location" />
            </div>
            <collection-block v-if="content!==null" :content="content"/>
          </div>
        </div>
        <div class="col-12 col-lg-3 pl-lg-3" style="display:none;">
          <div class="reactions mt-3 mt-md-0">
            <h6 class="title">Reaktionen</h6>
            <div class="reactions-list">
              <div class="d-flex">
                <div class="reactions-list__icon">
                  <span class="material-icons">person</span>
                </div>
                <div class="reactions-list__info">
                  <h6>VikingFan0815</h6>
                  <p>Klasse Museum, kann ich jedem Wikingerfan empfehlen! Habe mich fast selbst wie ein Wikinger gefühlt ;-)</p>
                </div>
              </div>
            </div>
            <div class="reactions-list">
              <div class="d-flex">
                <div class="reactions-list__icon">
                  <span class="material-icons">person</span>
                </div>
                <div class="form-group mb-0">
                  <textarea name="reaction" class="form-control" id="" placeholder="Füge eine Reaktion hinzu ..." cols="30" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  import TheMap from '@/components/map/single.vue';
  import Resource from '@/api/resource';
  import Appointment from '@/components/appointments/appointments.vue';
  import AppointmentsList from '@/components/appointments/appointmentsDropdownContents.vue';
  const contentResource = new Resource('contents');
  const institutionResource = new Resource('institutions');
  import { getFieldValues, showLoader, hideLoader, json_decode } from '@/utils/helpers';
  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';

  export default {
    name: 'EventView',
    components: {
      TheMap,
      CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
      CardSlider: () => import('@/components/CardSlider'),
      DetailBanner: () => import('@/components/DetailBanner'),
      ShareButton: () => import('@/components/controls/ShareButton'),
      BookmarkButton: () => import('@/components/controls/BookmarkButton'),
      AudioCard: () =>import('@/components/cards/CardAudio.vue'),
      Appointment,
      AppointmentsList,
      VueperSlides,
      VueperSlide
    },
    data: () => ({
      institution: null,
      content: null,
      showMap: true,
      showAppointments: false,
    }),
    watch: {
      id(newId, oldId) {
        if (newId!=oldId) {
          this.getContent();
          this.isActiveLocation=true;
          this.scrollToTop();
        }
      }
    },
    created() {
      this.getContent();
      this.isActiveLocation=true;
    },
    computed: {
      audioWalk(){
        if(this.content!==null){
         let audio = getFieldValues(this.content,'audio');
         if(audio){
          if(getFieldValues(audio, 'audio-datei')){
            return audio;
          }
        }
      }
      return null;
    },
      institutionId(){
        if(this.content !==null){
          return this.content.institution_id;
        }
        return null;
      },
      institutionName(){
        if(this.institution !==null){
          return this.institution.title;
        }
      },
      institutionContentId(){
        if(this.institution !==null){
          return this.institution.content.id;
        }
      },
      contentClass(){
        if(this.showAppointments || this.showMap){
          return "col-12 col-lg-6";
        }
        return "col-12";
      },
      id() {
        return this.$route.params.id;
      },
      title(){
        if(this.content!=null){
          return this.content.title;
        }
        return "";
      },
      categories(){
        return getFieldValues(this.content,'kategorien');
      },
      category(){
        if(this.categories!=null && Array.isArray(this.categories) && this.categories.length>0){
          return this.categories[0];
        }
        else if(this.categories!=null && !Array.isArray(this.categories) ){
          return this.categories;
        }
        return null
      },
      readAbleCategories(){
        if(this.categories!=null && Array.isArray(this.categories) && this.categories.length>0){
          return this.categories.join(", ");
        }
        else if(this.categories!=null && !Array.isArray(this.categories) ){
          return this.categories;
        }
        return null
      },
      addressObj(){
        return getFieldValues(this.content,'adresse');
      },
      street(){
        if(this.addressObj!=null){
          return this.addressObj.street;
        }
        return '';
      },
      city(){
        if(this.addressObj!=null){
          return this.addressObj.zipcode+' '+this.addressObj.city;
        }
        return '';
      },
      appointments(){
        return getFieldValues(this.content,'appointments');
      },
      description(){
        var desc = getFieldValues(this.content,'description');
        return desc!=null?desc:'';
      },
      teaserImages(){
        var pictures = getFieldValues(this.content,'teaser-bild');
        if(pictures != null){
          if(Array.isArray(pictures)){
            return pictures;
          }
          else{
            return [pictures];
          }
        }
        return null;
      },
      logo(){
        var logo = getFieldValues(this.content,'logo');
        return json_decode(logo);
      },
      url(){
        return getFieldValues(this.content,'url');
      },
      shortenedUrl(){
        if(this.url!=null){
          return this.url.length>26?this.url.substring(0,26)+' ...':this.url;
        }
      },
      currentPath(){
        return window.location.href;
      },
      shareViaEmail(){
        return "mailto:?body="+this.currentPath;
      },
      slides(){
        var images = [];
        if(this.logo != null){
          var logo = json_decode(this.logo);
          if(typeof logo === "object"){
           if(logo.path.indexOf('https')!==-1){
            images.push({image:logo.path,class:'logo'});
          }
          else{
            images.push({image:this.$backendUrl+logo.path,class:'logo'});
          }
        }
        else {
         if(logo.indexOf('https')!==-1){
          images.push({image:logo,class:'logo'});
        }
        else{
          images.push({image:this.$backendUrl+logo,class:'logo'});
        }
      }
    }

    if(this.teaserImages != null){
      for(var i = 0; i < this.teaserImages.length; i++){
        var image = json_decode(this.teaserImages[i]);

        if(typeof image === "object"){
          if(image.path.indexOf('https')!==-1){
            images.push({image:image.path,class:'teaser-img'});
          }
          else{
            images.push({image:this.$backendUrl+image.path,class:'teaser-img'});
          }
        }
        else{
         if(image.indexOf('https')!==-1){
          images.push({image: image,class:'teaser-img'});
        }
        else{
          images.push({image: this.$backendUrl+image,class:'teaser-img'});
        }
      }
    }
  }

  if(images.length == 0){
    images.push({image:this.getCategoryPlaceholderImage(this.category),class:'placeholder-img'});
  }
  return images;
},
},
methods:{
  json_decode,
  getFieldValues, 
  getCategoryPlaceholderImage,
  showLoader, 
  hideLoader, 
  getContent() {
    this.loader = this.showLoader(this.loader);
    contentResource.get(this.id)
    .then(response => {
      this.content = response.data;
        //this.getSimilarContents();
    })
    .finally(() => {
      this.getInstitution();
      this.loader = this.hideLoader(this.loader);
    });
  },
  getInstitution() {
    if(this.institutionId !== null){
      institutionResource.get(this.institutionId)
      .then(response => {
        this.institution = response.data;
      })
      .finally(() => {
      });
    }
  },
  toggleAppointments(){
    if(this.showAppointments==true){
      this.showAppointments=false;
    }
    else{
      this.showAppointments=true;
      this.showMap=false;
    }
  },
  toggleMap(){
    if(this.showMap==true){
      this.showMap=false;
    }
    else{
      this.showMap=true;
      this.showAppointments=false;
    }
  }
}
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content.detailed .main-title{
    @media (max-width: 760px) {
      top:-120px;
    }

    @media (max-width: 510px) {
      top:-150px;
    }
  }

  .mobile-bookmark-btn{
    display:none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 0;
      position: relative;
      top: -100px;
      z-index: 100;
      height: 0;

      .btn-bookmark{
        margin-right: 10px;
        position: absolute;
        bottom: -120px;
        right: 0;
      }

    }
  }

  .desktop-bookmark-btn{
    @media (max-width: 760px) {
      display:none;
    }
  }
  .page-content.detailed .main-title{
    @media (max-width: 760px) {
      top:-120px;
    }

    @media (max-width: 510px) {
      top:-150px;
    }
  }

  .mobile-bookmark-btn{
    display:none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 0;
      position: relative;
      top: -100px;
      z-index: 100;
      height: 0;

      .btn-bookmark{
        margin-right: 10px;
        position: absolute;
        bottom: -120px;
        right: 0;
      }

    }
  }

  .desktop-bookmark-btn{
    @media (max-width: 760px) {
      display:none;
    }
  }


  #horizontal-style {
  padding-inline-start: 0 !important; // Just in case if you find that there is an extra padding at the start of the line 
  justify-content: space-around;
  display: flex;

  @include responsive($scroll-breakpoint){
    flex-direction: column;
    margin-bottom:10px;
    margin-top:20px;

    li{
      padding-bottom:10px;
    }

    li:last-of-type{
      padding-bottom:0px;
    }
  }

}

.page-content.detailed .info--list li{
  margin-right: 0px;
}

.page-content.detailed .main-title h1{

  @include responsive($scroll-breakpoint){
    max-width: calc(100% - 80px);
  }
}

.content-wrapper{
  padding: 30px 0;

  @include responsive($scroll-breakpoint) {
    padding: 0px;
  }

  .col-12.col-lg-6 .text-wrapper{
    @include responsive($scroll-breakpoint) {
      padding: 30px 0;      
    }
  }

}

.col-lg-6{

  .text-wrapper{
    padding-left: 20px;

    @include responsive($scroll-breakpoint) {
      padding-left: 0px;
    }

  }

  .map-wrapper{

    width: 100%;
    height: 100%;
    padding-right: 20px;

    @include responsive($scroll-breakpoint) {
      padding-right: 0px;
    }

    .map{
      width:100%; 
    }
  }
}

ul.info--list{

  li.info-list{ 

    .btn-link {
      text-align: left;
    }

    a {
      font-family: "Comfortaa", cursive;color: #686B6F;

      &:hover{
        border-color: #E5321B;
        color: #E5321B;
      }
    }

  }
}

.footer-margin{
  @include responsive($scroll-breakpoint) {
    margin-bottom: 0vh;
  }
}

.page-content.detailed {
  @media (max-width:991px){
    padding-bottom: 80px;
  }
}

.institution-name {
  @media (max-width: 991px) {
    max-width: calc(100% - 80px);
  }
}

</style>